import VtpImageTeaserCropper from './vtp-image-teaser-cropper';

export default class VtpImageTeaser extends HTMLElement {
	/**
	* constructor (private)
	* @returns {void}
	*/
	constructor() {
		super();
		this.randomID = undefined;
		this.showResultLink = this._showResultLink.bind(this);
		this.handleFilterOptionClick = this._handleFilterOptionClick.bind(this);
		this.filterOptions = [];
		this.filterCheckboxes = [];

		this._initializeModule = this._initializeModule.bind(this);
	}

	/**
	* connectedCallback - callback for adding element to dom/shadow dom
	* @returns {void}
	*/
	connectedCallback() {
		this._initializeModule();
		this._bindEvents();
	}

	/**
	* disconnectedCallback - callback for removing element from dom/shadow dom
	* @returns {void}
	*/
	disconnectedCallback() {
		this._unbindEvents();
	}

	/**
	* @returns {void}
	*/
	_initializeModule() {
		this.randomID = this.getAttribute('id');
		this.filterCheckboxes = this.querySelectorAll('.nm-vtp-image-teaser__additional-filter-input');
		this.filterOptions = this.querySelectorAll('.nm-vtp-image-teaser__filter-option');

		this._initBackgroundImageCropper();
		this._applyConfigurations();
	}

	/**
	* @returns {void}
	*/
	_initBackgroundImageCropper() {
		const backgroundImageContainer = this.querySelector('.nm-vtp-image-teaser__body');

		this.VtpImageTeaserCropper = new VtpImageTeaserCropper(backgroundImageContainer);
	}

	/**
	* @returns {void}
	*/
	_bindEvents() {
		this._bindFilterCheckboxEvents();
		this._bindFilterOptionEvents();
	}

	/**
	* @returns {void}
	*/
	_unbindEvents() {
		this._unbindFilterCheckboxEvents();
		this._unbindFilterOptionEvents();
		this.VtpImageTeaserCropper.destroy();
	}

	/**
	* @returns {void}
	*/
	_bindFilterCheckboxEvents() {
		const filterCheckboxLength = this.filterCheckboxes.length;

		for (let i = 0; i < filterCheckboxLength; i++) {
			this.filterCheckboxes[i].addEventListener('click', this.showResultLink);
		}
	}

	/**
	* @returns {void}
	*/
	_unbindFilterCheckboxEvents() {
		const filterCheckboxLength = this.filterCheckboxes.length;

		for (let i = 0; i < filterCheckboxLength; i++) {
			this.filterCheckboxes[i].removeEventListener('click', this.showResultLink);
		}
	}

	/**
	* @returns {void}
	*/
	_bindFilterOptionEvents() {
		const filterOptionsLength = this.filterOptions.length;

		for (let i = 0; i < filterOptionsLength; i++) {
			this.filterOptions[i].addEventListener('click', this.handleFilterOptionClick);
		}
	}

	/**
	* @returns {void}
	*/
	_unbindFilterOptionEvents() {
		const filterOptionsLength = this.filterOptions.length;

		for (let i = 0; i < filterOptionsLength; i++) {
			this.filterOptions[i].removeEventListener('click', this.handleFilterOptionClick);
		}
	}

	/**
	 * @param {Event} event_ - click event
	 * @returns {void}
	*/
	_handleFilterOptionClick(event_) {
		if (this._isInputElement(event_.target)) {
			this._applyConfigurations();
		}
	}

	/**
	 * _applyConfigurations - read out options and checkboxes, apply them and trigger showing result
	 * @returns {void}
	*/
	_applyConfigurations() {
		const activeFilterOption = this._getActiveFilterOption();
		const checkboxesToDisable = this._getDisabledCheckboxes(activeFilterOption);

		this._setCheckboxStates(checkboxesToDisable);
		this._showResultLink();
	}

	/**
	 * @param {HTMLElement} filterOptionElement_ - filter option
	 * @returns {Array} disabledCheckboxes - checkboxes that will be disabled and unchecked by option
	*/
	_getDisabledCheckboxes(filterOptionElement_) {
		let disabledCheckboxes = [];
		let disabledCheckboxesStr;

		if (filterOptionElement_) {
			disabledCheckboxesStr = filterOptionElement_.dataset.disables;
			disabledCheckboxes = this._getStringChainAsArray(disabledCheckboxesStr);
		}

		return disabledCheckboxes;
	}

	/**
	 * _getStringChainAsArray - returns string as array without empty elements
	 * @param {string} string_ - string chain
	 * @return {Array} - array of string items
	*/
	_getStringChainAsArray(string_) {
		return string_.split(' ').filter(x => x);
	}

	/**
	 * _setCheckboxStates - disable and uncheck checkboxes listed in given array
	 * @param {Array} toDisableCheckboxes_ - list of to disable and uncheck checkboxes
	 * @returns {void}
	*/
	_setCheckboxStates(toDisableCheckboxes_) {
		const filterCheckboxesLength = this.filterCheckboxes.length;

		for (let i = 0; i < filterCheckboxesLength; i++) {
			let filterCheckbox = this.filterCheckboxes[i];
			let filterCheckboxValue = filterCheckbox.value;

			if (toDisableCheckboxes_.indexOf(filterCheckboxValue) >= 0) {
				this._unselectCheckbox(filterCheckbox);
				this._disableCheckbox(filterCheckbox);
			}
			else {
				this._enableCheckbox(filterCheckbox);
			}
		}
	}

	/**
	 * @returns {void}
	*/
	_showResultLink() {
		let resultID = this._getResultLinkID();

		if (resultID !== '') {
			this._setResultLinkActiveClassById(resultID);
		}
	}

	/**
	 * @returns {string} - resultLink id
	*/
	_getResultLinkID() {
		const activeFilterOption = this._getActiveFilterOption();
		let resultIdPrefix = '';
		let resultId = '';

		if (activeFilterOption) {
			const filterOptionInput = activeFilterOption.querySelector('input');
			const filterCheckboxesLength = this.filterCheckboxes.length;

			resultIdPrefix = this.randomID + '-resultLink-';
			resultId = filterOptionInput.value;

			for (let i = 0; i < filterCheckboxesLength; i++) {
				let filterCheckbox = this.filterCheckboxes[i];

				if (this._isChecked(filterCheckbox)) {
					resultId += `-${filterCheckbox.value}`;
				}
			}
		}

		return resultIdPrefix + resultId;
	}

	/**
	 * @returns {HTMLElement} - active filter option
	*/
	_getActiveFilterOption() {
		let activeFilterOption;
		let checkedFilterOption = this.querySelector('.nm-vtp-image-teaser__filter-option-input:checked');

		if (checkedFilterOption) {
			activeFilterOption = checkedFilterOption.parentElement;
		}

		return activeFilterOption;
	}

	/**
	 * @param {string} id_ - result link id
	 * @returns {void}
	*/
	_setResultLinkActiveClassById(id_) {
		const CLASS_RESULTLINK_ACTIVE = 'nm-vtp-image-teaser__link--active';
		let activeLink = this.querySelector('.' + CLASS_RESULTLINK_ACTIVE);

		if (activeLink) {
			activeLink.classList.remove(CLASS_RESULTLINK_ACTIVE);
		}

		activeLink = this.querySelector('#' + id_);

		if (activeLink) {
			activeLink.classList.add(CLASS_RESULTLINK_ACTIVE);
		}
	}

	/**
	 * @param {HtmlElement} element_ - html element
	 * @returns {boolean} - is input element (true) or not (false)
	*/
	_isInputElement(element_) {
		return element_.tagName.toLowerCase() === 'input';
	}

	/**
	 * @param {HTMLElement} checkbox_ - checkbox
	 * @returns {void}
	*/
	_unselectCheckbox(checkbox_) {
		checkbox_.checked = false;
	}

	/**
	 * @param {HTMLElement} checkbox_ - checkbox
	 * @returns {void}
	*/
	_enableCheckbox(checkbox_) {
		checkbox_.disabled = false;
	}

	/**
	 * @param {HTMLElement} checkbox_ - checkbox
	 * @returns {void}
	*/
	_disableCheckbox(checkbox_) {
		checkbox_.disabled = true;
	}

	/**
	 * @param {HTMLElement} inputElement_ - input element
	 * @returns {boolean} - is input element checked (true) or not (false)
	*/
	_isChecked(inputElement_) {
		return inputElement_.checked;
	}
}

if (window.customElements.get('audi-vtp-image-teaser') === undefined) {
	window.customElements.define('audi-vtp-image-teaser', VtpImageTeaser);
}

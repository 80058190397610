import {dom} from "core-utils";

export default class VtpImageTeaserCropper {
	static get HEIGHT_IMAGE_ORIGINAL() {
		return 715;
	}
	static get WIDTH_IMAGE_ORIGINAL() {
		return 1920;
	}
	static get BREAKPOINTS() {
		return [
			{
				from: 0,
				to: 520,
				aspectRatioX: 1,
				aspectRatioY: 1
			},
			{
				from: 520,
				to: 767
			},
			{
				from: 767,
				to: 900
			},
			{
				from: 900,
				to: 1270
			},
			{
				from: 1270,
				to: 1400
			},
			{
				from: 1400,
				to: 1650
			},
			{
				from: 1650,
				to: null
			}
		];
	}

	/**
	 * constructor (private)
	 * @param {HTMLElement} backgroundImageContainer_ - vtp teaser div that should receive background image
	 * @returns {void}
	 */
	constructor(backgroundImageContainer_) {
		this.handleWindowResizeEvent = dom.throttle(
			this._handleWindowResizeEvent.bind(this),
			100
		);
		this.backgroundImageContainer = backgroundImageContainer_;
		this.akamaiParameters = [];

		this._init();
	}

	/**
	 * (public) destroy - called if cropper instance is not needed anymore
	 * @returns {void}
	 */
	destroy() {
		this._unbindResizeEvent();
	}

	/**
	 * @returns {void}
	 */
	_init() {
		this._initAkamaiParameterSet();
		this._initBackgroundImage();
		this._bindResizeEvent();
	}

	/**
	 * _initAkamaiParameterSet - generates array for storing all needed akamai parameters
	 * @returns {void}
	 */
	_initAkamaiParameterSet() {
		const BREAKPOINTS = VtpImageTeaserCropper.BREAKPOINTS;

		for (let i = 0; i < BREAKPOINTS.length; i++) {
			if (BREAKPOINTS[i].to) {
				// for last breakpoint no parameter is stored --> will not be cropped
				let akamaiParameter = this._generateAkamaiParamForBreakpoint(
					BREAKPOINTS[i]
				);

				this.akamaiParameters[BREAKPOINTS[i].from] = akamaiParameter;
			}
		}
	}

	/**
	 * @param {Object} breakpoint_ - breakpoint information
	 * @param {Number} breakpoint_.to - end of breakpoint
	 * @param {Number} breakpoint_.aspectRatioX - aspect ration x value
	 * @param {Number} breakpoint_.aspectRatioY - aspect ration y value
	 * @returns {String} - akamai crop paramater
	 */
	_generateAkamaiParamForBreakpoint(breakpoint_) {
		const breakpointTo = breakpoint_.to;
		const aspectRatioX = breakpoint_.aspectRatioX;
		const aspectRatioY = breakpoint_.aspectRatioY;
		const cropImageHeight = this._calculateCropImageHeight(
			breakpointTo,
			aspectRatioX,
			aspectRatioY
		);
		const cropOffsetX = this._calculateCropOffsetX(breakpointTo);
		const cropOffsetY = this._calculateCropOffsetY(cropImageHeight);

		return `&crop=${breakpointTo}px:${cropImageHeight}px;${cropOffsetX},${cropOffsetY};`;
	}

	/**
	 * @param {Number} widthImage_ - width of cropped image
	 * @param {Number} aspectRatioX_ - aspect ration width param
	 * @param {Number} aspectRatioY_ - aspect ration height param
	 * @returns {Number} - cropped image height
	 */
	_calculateCropImageHeight(
		widthImage_,
		aspectRatioX_ = 16,
		aspectRatioY_ = 9
	) {
		return Math.round((aspectRatioY_ * widthImage_) / aspectRatioX_);
	}

	/**
	 * @param {Number} cropImageWidth_ - width of cropped image
	 * @returns {Number} - cropped image offset relaive to original image
	 */
	_calculateCropOffsetX(cropImageWidth_) {
		let cropOffsetX = Math.round(
			(VtpImageTeaserCropper.WIDTH_IMAGE_ORIGINAL - cropImageWidth_) / 2
		);

		if (cropOffsetX < 0) {
			cropOffsetX = 0;
		}

		return cropOffsetX;
	}

	/**
	 * @param {Number} cropImageHeight_ - height of cropped image
	 * @returns {Number} - cropped image offset relative to original image
	 */
	_calculateCropOffsetY(cropImageHeight_) {
		let cropOffsetY = Math.round(
			(VtpImageTeaserCropper.HEIGHT_IMAGE_ORIGINAL - cropImageHeight_) / 2
		);

		if (cropOffsetY < 0) {
			cropOffsetY = 0;
		}

		return cropOffsetY;
	}

	/**
	 * @returns {void}
	 */
	_bindResizeEvent() {
		window.addEventListener("resize", this.handleWindowResizeEvent);
	}

	/**
	 * @returns {void}
	 */
	_unbindResizeEvent() {
		window.removeEventListener("resize", this.handleWindowResizeEvent);
	}

	/**
	 * _initBackgroundImage - initial set of background image
	 * @returns {void}
	 */
	_initBackgroundImage() {
		this.maxLoadedImageBreakpoint = this._getActualAkamaiParameterIndex();
		this._setBackgroundImage(this.maxLoadedImageBreakpoint);
	}

	/**
	 * _handleWindowResizeEvent - call back on window resize
	 * @returns {void}
	 */
	_handleWindowResizeEvent() {
		let akamaiParamIndex = this._getActualAkamaiParameterIndex();

		if (!this._isBiggerImageAlreadyLoaded(akamaiParamIndex)) {
			// when there was no bigger image loaded before
			// load image and use it until a bigger one is needed
			this.maxLoadedImageBreakpoint = akamaiParamIndex;
			this._setBackgroundImage(akamaiParamIndex);
		}
	}

	/**
	 * _getActualAkamaiParameterIndex - returns key of akamaiParameter-Array for actual loaded background-image
	 * @returns {void}
	 */
	_getActualAkamaiParameterIndex() {
		return [...VtpImageTeaserCropper.BREAKPOINTS]
			.reverse()
			.find((breakpoint_) => window.innerWidth > breakpoint_.from).from;
	}

	/**
	 * _isBiggerImageAlreadyLoaded - determinates if there was a bigger image loaded before
	 * @param {Number} breakpointFrom_ - breakpoint min width
	 * @returns {Boolean} - true => bigger image was laoded
	 */
	_isBiggerImageAlreadyLoaded(breakpointFrom_) {
		return this.maxLoadedImageBreakpoint >= breakpointFrom_;
	}

	/**
	 * @param {Number} akamaiParamIndex_ - index for crop parameter
	 * @returns {void}
	 */
	_setBackgroundImage(akamaiParamIndex_) {
		let backgroundImage = this.backgroundImageContainer.getAttribute(
			"data-background-image"
		);
		const akamaiParameter = this.akamaiParameters[akamaiParamIndex_]
			? this.akamaiParameters[akamaiParamIndex_]
			: "";

		backgroundImage = `${backgroundImage}${akamaiParameter}`;

		this.backgroundImageContainer.style.backgroundImage = `url("${backgroundImage}")`;
	}
}
